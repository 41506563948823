<template>
  <section class="section is-title-bar">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <ul>
            <li
              v-for="(menu, index) in titleStack"
              :key="index"
            >
              <router-link class="text-black" v-if="menu.routeName" :to="{name: menu.routeName, params: menu.params}">{{ menu.name }}</router-link>
              <span v-else>{{ menu.name }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <div class="buttons is-right" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TitleBarNew',
  props: {
    titleStack: {
      type: Array,
      default: () => []
    }
  }
}
</script>
